import { render, staticRenderFns } from "./Art.vue?vue&type=template&id=6dffdf12&scoped=true"
import script from "./Art.vue?vue&type=script&lang=js"
export * from "./Art.vue?vue&type=script&lang=js"
import style0 from "./Art.vue?vue&type=style&index=0&id=6dffdf12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dffdf12",
  null
  
)

export default component.exports